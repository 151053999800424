<template>
  <div class="content">
    <div class="title">
      <div class="title-text"><span>*</span> 问卷标题：</div>
      <div style="flex: 1;"><el-input v-model="title"></el-input></div>
    </div>
    <div class="desc">
      <div>问卷描述：</div>
      <div style="flex: 1;">
        <el-input type="textarea" v-model="description"></el-input>
      </div>
    </div>
    <div class="body">
      <div class="body-button">
        <el-button @click="_ => addChoiceQuestion('single_choice')"
          style="margin-left: 10px;margin-bottom: 10px;">单选题</el-button>
        <el-button @click="_ => addChoiceQuestion('multiple_choice')" style="margin-bottom: 10px;">多选题</el-button>
        <el-button @click="_ => addTextQuestion('single_text')" style="margin-bottom: 10px;">单文本</el-button>
        <el-button @click="_ => addTextQuestion('multiple_text')">多文本</el-button>
      </div>
      <div class="body-list">
        <div v-for="(item, index) in question_volist" :key="index" class="body-list-item">
          <component :is="item.question_type" :itemIndex="index + 1" @delete="_ => handleDelete(index)" :data="item" />
        </div>
      </div>
    </div>
    <div class="floot">
      <div>
        <el-button @click="_ => ($emit('cancel'), reset())">取消</el-button>
        <el-button type="primary" @click="submit">{{ question_id ? '编辑' : '提交' }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import single_choice from './radio.vue';
import multiple_choice from './multi.vue';
import single_text from './single-text.vue'
import multiple_text from './multiple-text.vue'
import { choiceQuestion, textQuestion } from './utils'
import { getQuestionnaire, addQuestionnaire, editQuestionnaire } from '@/api/questionnaire';
export default {
  name: 'ManagerDoubleSelectionQuestionnaireDialig',
  components: { single_choice, multiple_choice, single_text, multiple_text },
  props: {
    question_id: {
      type: Number | String,
      default: ''
    }
  },
  data () {
    return {
      title: '',
      description: '',
      question_volist: []
    };
  },
  watch: {
    question_id: {
      handler (val, oldval) {
        if (val) {
          if (val) this.getQuestionnaire(val)
        }
      },
      immediate: true
    }
  },
  mounted () {

  },

  methods: {
    async getQuestionnaire (val) {
      const { data: { title, description, question_volist } } = await getQuestionnaire(val)
      this.title = title;
      this.description = description;
      this.question_volist = question_volist.map(question => {
        if (question.question_type.includes('text')) return new textQuestion(question.question_type, question)
        return new choiceQuestion(question.question_type, question)
      })

    },
    reset () {
      this.title = '';
      this.description = '';
      this.question_volist = [];
    },
    handleDelete (index) {
      this.question_volist.splice(index, 1);
    },
    addChoiceQuestion (type) {
      this.question_volist.push(new choiceQuestion(type));
    },
    addTextQuestion (type) {
      this.question_volist.push(new textQuestion(type));
    },
    async submit () {
      if (!this.title || !this.question_volist.length) return this.$message.error('请填写完整');
      const { title, description, question_volist, question_id } = this
      if (this.question_id) await editQuestionnaire({ id: this.question_id, title, description, question_volist })
      else await addQuestionnaire({ title, description, question_volist });
      console.log(this.question_volist);
      this.$emit('cancel')
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  overflow: auto;
  background-color: #fff;
  padding-top: 20px;

  .desc,
  .title {
    position: relative;
    display: flex;
    padding: 0 60px;
    margin-bottom: 20px;

    &-text {
      position: relative;

      span {
        position: absolute;
        left: -.5em;
        top: 0;
        color: red;
        font-size: 16px;
      }

    }
  }

  .body {
    flex: 1;
    display: flex;
    margin-top: 20px;
    overflow: hidden;

    &-button {
      display: flex;
      flex-direction: column;
      margin-right: 6px;
    }

    &-list {
      flex: 1;
      border-left: 1px solid #dfe2e8;
      padding: 20px;
      padding-bottom: 100px;
      overflow: auto;


      &-item {
        margin-bottom: 26px;
      }
    }
  }

  .floot {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>