<template>
  <div>
    <div class="question">
      <div style="display: flex; flex: 1;align-items: center;">
        <div>{{ itemIndex }}、</div>
        <div style="flex: 1;"><el-input v-model="data.question_title"></el-input></div>
      </div>
      <div style="margin-left: 130px;">
        <el-button @click="_ => $emit('delete')" icon="el-icon-delete">删除</el-button>
      </div>
    </div>
    <div style="padding: 0 3em;">
      <el-input type="textarea" disabled></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManagerDoubleSelectionMultipleText',
  props: {
    itemIndex: {
      required: true,
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => { }
    },
  },
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.question {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

}
</style>