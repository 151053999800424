<template>
  <div>
    <div class="question">
      <div style="display: flex; flex: 1;align-items: center;">
        <div>{{ itemIndex }}、</div>
        <div style="flex: 1;"><el-input v-model="data.question_title"></el-input></div>
      </div>
      <div>（多选）</div>
      <el-button @click="_ => data.add()">添加选项</el-button>
      <div style="margin-left: 30px;">
        <el-button @click="_ => $emit('delete')" icon="el-icon-delete">删除</el-button>
      </div>
    </div>
    <div class="options">
      <div v-for="(option, index) in data.option_list" :key="option.id" class="options-item">
        <div style="margin: 0  10px 0 3em;">选项{{ index + 1 }}</div>
        <div style="margin-right: 10px;"><el-input v-model="option.option_name"></el-input></div>
        <el-button size="mini" v-if="index" @click="_ => (data.up(index), $forceUpdate())" icon="el-icon-top"
          circle></el-button>
        <el-button size="mini" v-if="data.option_list.length - 1 !== index"
          @click="_ => (data.down(index), $forceUpdate())" icon="el-icon-bottom" circle></el-button>
        <el-button size="mini" @click="_ => data.add(index)" icon="el-icon-plus" circle></el-button>
        <el-button size="mini" @click="_ => data.remove(index)" icon="el-icon-minus" circle></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManagerDoubleSelectionMulti',
  props: {
    itemIndex: {
      required: true,
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => { }
    },
  },
  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.question {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

}

.options {
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
}
</style>