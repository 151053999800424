export class choiceQuestion {
  question_title = ''
  question_type = ''// 问题类型:single_choice:单选; multiple_choice:多选; single_text:单文本; multiple_text:多文本
  option_list = []
// 构造函数
  constructor(question_type = '',opt) {
    this.question_type = question_type
    if (opt)  Object.keys(opt).forEach(key => this[key] = opt[key])
  }
  // 上移
  up(index) {
    if (index === 0) return;
    const temp = this.option_list[index];
    this.option_list[index] = this.option_list[index - 1];
    this.option_list[index - 1] = temp;
  }
  // 下移
  down (index) {
    if (index === this.option_list.length - 1) return;
    const temp = this.option_list[index];
    this.option_list[index] = this.option_list[index + 1];
    this.option_list[index + 1] = temp;
  }
  // 删除
  remove(index) {
    this.option_list.splice(index, 1);
  }
  // 添加
  add (index) {
    if(index === void 0) return  this.option_list.push({ option_name:'' });
    const item = { option_name:'' }
    this.option_list.splice(index + 1, 0, item);
  }
  getId () {
    return  Math.random().toString(36).substring(2, 10)
  }
}

export class textQuestion {
  question_type = ''; // 名称 singleText multipleText
  question_title = ''
  survey_result_detail_dolist = [{}]
  constructor(question_type = '',opt) {
    this.question_type = question_type;
    if (opt)  Object.keys(opt).forEach(key => this[key] = opt[key])
  }

}
