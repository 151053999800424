<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div style="width: 100%;margin-top:10px">
          <el-form-item label="更新时间" class="col-auto">
            <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
              size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item prop="name">
            <el-input style="width: 315px" size="medium" placeholder="请输入" v-model="keyword" clearable>
              <el-select slot="prepend" v-model="key_word" style="width: 120px">
                <el-option value="title" label="问卷标题"></el-option>
                <el-option value="oper_name" label="操作人名称"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
            <el-button @click="addNotice()" type="primary" size="small">添加</el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="问卷标题" prop="title" />
        <el-table-column label="更新时间" width="220">
          <template slot-scope="{row}">{{ row.update_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="启用状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.enable_status" :active-value="1" :inactive-value="0" active-color="#2155d5"
              inactive-color="#ff4949" @change="editNotice(scope.row, 'open')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="update_by_name" />
        <el-table-column label="操作" width="240">
          <template slot-scope="{row}">
            <el-button v-if="row.enable_status == 0 && row.has_survey_result !== 1" size="mini" type="primary"
              @click="editNotice(row, 'edit')">修改</el-button>
            <el-button size="mini" type="primary" @click="editNotice(row, 'info')">调查结果</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--修改|新增-->
    <el-dialog-x-dialog :title="loadingtitle" :visible.sync="editVisible" ref="questiondialog">
      <questionnaireDialig ref="question" @cancel="cancel" :question_id="question_id"></questionnaireDialig>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import { questionnaireList, addQuestionnaire, editQuestionnaire, getQuestionnaire, updateStatus } from '@/api/questionnaire';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import questionnaireDialig from './components/questionnaire-dialig.vue'
export default {
  name: 'questionnaire',
  components: { EnTableLayout, XDialog, questionnaireDialig },
  data () {
    return {
      question_id: '',
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      editVisible: false,//审核页
      loadingtitle: '',//弹窗标题
      /** 列表loading状态 */
      loading: false,
      txInfo: {//审核表单内容
        name: '',
        addr: '',
        province_id: "",
        city_id: "",
        county_id: "",
        town_id: ""
      },
      txRules: {//审核表单验证
        name: [
          { required: true, message: '请输入门店名称', trigger: 'blur' }],
        province_id: [
          { required: true, message: '请选择商户地址', trigger: 'change' }],
        addr: [
          { required: true, message: '请输入详细地址', trigger: 'change' }
        ],
      },
      keyword: "",
      key_word: "title",
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20
      },
      /** 高级搜索数据 */
      advancedForm: {
        order_time_range: [],
      },
      /** 列表数据 */
      tableData: {},
    };
  },
  filters: {

  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    /** 搜索事件触发 */
    searchEvent () {
      delete this.params.oper_name;
      delete this.params.title;
      this.params = {
        ...this.params,
        ...this.advancedForm
      };
      this.params[this.key_word] = this.keyword;
      delete this.params.start_update_time;
      delete this.params.end_update_time;
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.start_update_time =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.end_update_time =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_update_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end_update_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      delete this.params.order_time_range;
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /**关闭弹窗 */
    cancel () {
      this.editVisible = false
      this.GET_AccountList();
    },
    /**新增 */
    addNotice () {
      this.question_id = ''
      this.loadingtitle = "添加问卷"
      this.txInfo = {//审核表单内容
        name: '',
        addr: '',
        province_id: "",
        city_id: "",
        county_id: "",
        town_id: ""
      }
      // this.getAreaHandle("province");
      this.editVisible = true
    },
    /**打开弹窗 */
    async editNotice (row, type) {
      this.question_id = row.id
      switch (type) {//获取地址
        case "edit"://编辑
          this.loadingtitle = "编辑问卷"
          this.editVisible = true
          break;
        case "open"://启用  

          let type_name = row.enable_status ? "启用" : "停用";
          this.$confirm(`确定${type_name}该问卷吗?`, "提示", { type: "warning" }).then(() => {
            let obj = {
              id: row.id,
              enable_status: row.enable_status
            }
            updateStatus(obj).then(res => {
              this.$message.success(`${type_name}成功`);
              this.GET_AccountList();
            }).catch(() => { row.enable_status = row.enable_status == 0 ? 1 : 0 })
          }).catch(() => { row.enable_status = row.enable_status == 0 ? 1 : 0 });
          break;
        default://调查结果
          this.$router.push({ path: "/setting/questionnaireDetail", query: { id: row.id } });
          break;
      }

    },
    /**提交 */
    accountSubmit () {
      this.$refs.txInfo.validate(valid => {
        let datas = {
          ...this.txInfo
        }

        if (valid) {
          if (this.txInfo.id) {//编辑         
            editQuestionnaire(datas).then((response) => {
              this.$message.success('调查问卷修改成功！');
              this.editVisible = false;
              this.GET_AccountList();
            })
          } else {//添加
            addQuestionnaire(datas).then((response) => {
              this.$message.success('调查问卷添加成功！');
              this.editVisible = false;
              this.GET_AccountList();
            })
          }
        }
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },
    GET_AccountList () {
      this.loading = true;
      let params = this.params;
      questionnaireList(params).then(response => {
        this.loading = false;
        this.tableData = response.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

.dialog-footer {
  text-align: center;
  margin: 20px 0;
}
</style>
